import { useQuery } from '@tanstack/react-query';
import { useContext, useEffect, useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { Button, Card, CardBody, Col, Container, Modal, ModalBody, ModalHeader, Row, Alert } from 'reactstrap';
import { useApp } from 'src/components/App/appContext';
import Spinner from 'src/components/Spinner';
import { CartContext } from 'src/contexts/CartContext';
import graphql from 'src/utils/graphql';

import CodeChecker from 'src/components/CodeChecker';
import ProductModal from 'src/components/ProductModal';
import ViewAvailabilityModal from 'src/components/ViewAvailabilityModal';

export default function Enroll(props) {
	const navigator = useNavigate();
	let { id, activityId } = useParams();
	const [autoOpenActivity, setAutoOpenActivity] = useState(activityId);

	useEffect(() => {
		if (activityId) {
			setAutoOpenActivity(activityId);
			navigator(`/enrol/${id}`);
		}
	}, [activityId]);

	const cart = useContext(CartContext);

	const { settings, setSettings } = useApp();

	const [showModal, setShowModal] = useState(false);
	const [modalData, setModalData] = useState(null);

	const { data, isLoading, error } = useQuery(['sportActivities', id], async () => {
		const { sportByPk } = await graphql(
			{
				query: `#graphql
					query ($id: bigint!) {
						sportByPk(id: $id) {
							activities(orderBy: {displayOrder: ASC}, where: {product: {visible: {_eq: true}}}) {
								id
								product {
									id
									name
									price
									description
									imageObject {
										url
									}
								}
							}
						}
					}
			`,
				variables: {
					id
				}
			},
			true
		);
		return sportByPk;
	});

	if (isLoading) return <Spinner />;
	console.log(data);

	return (
		<>
			{/* flex with a gap */}
			<div style={{ display: 'flex', justifyContent: 'space-between' }}>
				<div>
					<Link to="/choose-sport">
						<Button color="primary" outline>
							<i className="fa fa-arrow-left" /> Back to sport selection
						</Button>
					</Link>
				</div>

				<div>
					<CodeChecker title="Redeem discount code">
						<Button color="primary" outline>
							<i className="fa fa-tag" /> Click here to use a discount code
						</Button>
					</CodeChecker>
				</div>
			</div>
			<Alert
				style={{
					backgroundColor: '#272a2f',
					borderColor: '#747c8b',
					color: 'white',
					textAlign: 'center'
				}}
				className="my-2"
				color="info"
			>
				<h5 style={{ fontSize: '1.25rem', color: 'white' }}>Course Bookings</h5>
				Once you purchase a course you will be able to book onto whichever days fit around your work / life / family
				using our online booking portal.
				<br /> The school is open 360 days per year and we aim to fly every day the weather allows.
			</Alert>
			<Row>
				{data.activities.map((activity, index) => {
					return (
						<ActivityCard
							defaultOpen={activity.id === Number(autoOpenActivity)}
							key={index}
							product={activity.product}
							activityId={activity.id}
							onClick={() => {
								setModalData(activity);
								setShowModal(true);
							}}
						/>
					);
				})}
			</Row>
			<ActivityModal open={showModal} data={modalData} toggle={() => setShowModal(!showModal)} />
		</>
	);
}

const ActivityModal = ({ open, data, toggle }) => {
	if (!data) return null;
	const cart = useContext(CartContext);
	const { id, name, image, price, description, product } = data;
	return (
		<Modal isOpen={open} toggle={() => toggle()} centered size="xl">
			<ModalHeader>{name}</ModalHeader>
			<ModalBody style={{ padding: 0, color: '#595959' }}>
				<Container fluid>
					<Row>
						<Col md={6}>
							<img className="card-img-top img-fluid" src={image?.url} />
							<p>{description}</p>
						</Col>
						<Col md={6}>
							<Link to={`/calendar/${id}`}>
								<Button color="info" block>
									<i className="fa fa-calendar fa-fw"></i> <b>Check Availability</b>
								</Button>
							</Link>
							<Button
								className="mt-1"
								color="primary"
								block
								onClick={() => {
									if (product?.id) cart.addItem(product?.id, false);
								}}
							>
								<i className="fa fa-user fa-fw"></i> <b>Purchase</b>
							</Button>
							<Button
								className="mt-1"
								color="primary"
								block
								onClick={() => {
									if (product?.id) cart.addItem(product?.id, true);
								}}
							>
								<i className="fa fa-gift fa-fw"></i> <b>Purchase as Gift</b>
							</Button>
						</Col>
					</Row>
				</Container>
			</ModalBody>
		</Modal>
	);
};

const ActivityCard = ({ product, activityId, onClick, defaultOpen }) => {
	console.log(activityId);
	return (
		<Col sm={12} md={6} lg={6} xl={4} className="mb-4">
			<Card>
				<img className="card-img-top img-fluid" src={product.imageObject?.url || ''} />
				<CardBody>
					<h5 className="card-title">{product.name}</h5>
					<span className="card-text">
						<p>{product.description}</p>
						<p>
							Cost: <b>&pound;{product.price}</b>
						</p>
					</span>
					{product?.id && (
						<ProductModal id={product?.id} defaultOpen={defaultOpen}>
							<Button className="mb-1" color="primary" block>
								<i className="fa-solid fa-cart-shopping" /> Buy Now
							</Button>
						</ProductModal>
					)}
					{/* Unsure why this needs product id - BF */}
					<ViewAvailabilityModal activityId={activityId}>
						<Button size="sm" color="secondary" block>
							<i className="fa fa-calendar fa-fw"></i> Check Availability
						</Button>
					</ViewAvailabilityModal>

					{/* <Link to={`/calendar/${id}`}>
						<Button color="info" block>
							<i className="fa fa-calendar fa-fw"></i> <b>Check Availability</b>
						</Button>
					</Link>
					<Button color="primary" block onClick={onPurchase}>
						<i className="fa fa-user fa-fw"></i> <b>Purchase for myself</b>
					</Button>
					<Button color="warning" block>
						<i className="fa fa-gift fa-fw"></i> <b>Purchase as gift</b>
					</Button> */}
				</CardBody>
			</Card>
		</Col>
	);
};

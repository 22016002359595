import React, { useState, useCallback, useEffect } from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, Alert, Card, ListGroup, ListGroupItem } from 'reactstrap';
import moment from 'moment';
import { useApp } from 'src/components/App/appContext';
import { AskConfirm } from 'src/components/AskComponents';
import graphql from 'src/utils/graphql';
import Spinner from '../Spinner';
import './index.css';
import ProductModal from '../ProductModal';

export default function BookParticipationModal({
	activityId,
	participationId = null,
	onSubmit = () => {},
	children,
	setOpen,
	open
}) {
	const [monthIndex, setMonthIndex] = useState(0);
	const [error, setError] = useState(null);
	const [submitting, setSubmitting] = useState(false);
	const momentObj = moment().add(monthIndex, 'month');
	const month = momentObj.month() + 1;
	const year = momentObj.year();
	const [loading, setLoading] = useState(true);

	const toggle = () => setOpen(!open);

	const [availability, setAvailability] = useState(null);
	const fetch = useCallback(async () => {
		setLoading(true);
		const { activityByPk } = await graphql(
			{
				query: `query ($activityId:bigint!,$month:Int!, $year: Int!){
						activityByPk(id:$activityId){
							availableSlots(args:{month:$month,year:$year}){
								date
								slots
							}
						}
					}`,
				variables: {
					activityId,
					month: parseInt(month),
					year
				}
			},
			true
		);

		setAvailability(activityByPk?.availableSlots || []);
		setLoading(false);
	}, [activityId, monthIndex]);

	const bookParticipation = async (date) => {
		try {
			setSubmitting(true);
			setError(null);
			await graphql({
				query: `mutation($participationId: ID!, $date: String!) {
					bookParticipation(participationId: $participationId, date: $date)
				}`,
				variables: {
					participationId,
					date
				}
			});
			await onSubmit();
		} catch (error) {
			console.log(error);
			setError(error.message);
		} finally {
			setSubmitting(false);
		}
	};

	useEffect(() => {
		if (open) {
			fetch();
		}
	}, [open, fetch, month]);

	return (
		<div>
			<span onClick={toggle}>{children || 'View Booking'}</span>
			<Modal size="" isOpen={open} toggle={toggle}>
				<ModalHeader
					toggle={toggle}
					close={
						<>
							<div style={{ paddingLeft: '20px' }}>
								<Button
									outline
									color="primary"
									onClick={() => setMonthIndex(monthIndex - 1)}
									disabled={monthIndex === 0}
								>
									<i class="fa-solid fa-arrow-left"></i>
								</Button>
								<div style={{ display: 'inline-block', margin: '0 5px' }} />
								<Button outline color="primary" onClick={() => setMonthIndex(monthIndex + 1)}>
									<i class="fa-solid fa-arrow-right"></i>
								</Button>
							</div>

							<Button close outline color="primary" onClick={toggle} />
						</>
					}
				>
					{moment(`${year}-${month}-01`).format('MMM YYYY')}
				</ModalHeader>
				{availability && !loading ? (
					<ModalBody>
						{error && <Alert color="danger">{error}</Alert>}
						{!participationId && (
							<Alert color="info">You will be able to book available dates once the activity has been purchased.</Alert>
						)}

						<ListGroup flush style={{ overflowY: 'auto', maxHeight: '500px' }}>
							{/* Prev Month button */}
							{monthIndex > 0 && (
								<ListGroupItem style={{ display: 'inline-block', background: '#e8f3e8' }}>
									<div
										className="row"
										style={{
											cursor: 'pointer',
											'&:hover': {
												backgroundColor: '#f5f5f5'
											}
										}}
										onClick={() => {
											setMonthIndex(monthIndex - 1);
										}}
									>
										<div className="col-2" style={{ textAlign: 'center' }}>
											<i style={{ color: 'green' }} className="fa-solid fa-circle-arrow-left fa-xl" />
										</div>
										<div className="col-10" style={{ width: '50%' }}>
											<b style={{ color: 'green' }}>
												View {moment(`${year}-${month}-01`).subtract(1, 'month').format('MMMM')}
											</b>
										</div>
									</div>
								</ListGroupItem>
							)}

							{availability.map((day, index) => {
								let Comp = React.Fragment;
								if (!participationId) Comp = ProductModal; // Not purchase yet
								else if (day.slots > 0) Comp = AskConfirm; // Available
								// if day has passed continue
								if (moment(day.date).isBefore(moment().add(1, 'day'), 'day')) return null;

								return (
									<ListGroupItem style={{ display: 'inline-block' }} key={index}>
										<Comp
											id={activityId}
											title={`Are you sure you want to book this day?`}
											description={moment(day.date).format('dddd, MMMM Do YYYY')}
											onSubmit={async () => {
												if (!day.slots) return false;
												if (!participationId) return false;
												if (submitting) return false;
												await bookParticipation(day.date);
											}}
										>
											<div
												className={day.available ? 'row availableDay' : 'row'}
												style={
													day.slots > 0
														? {
																cursor: 'pointer',
																'&:hover': {
																	backgroundColor: '#f5f5f5'
																}
														  }
														: { cursor: 'not-allowed' }
												}
											>
												<div className="col-2" style={{ textAlign: 'center' }}>
													<span style={{ fontSize: '1.1em', fontWeight: 'bold' }}>{moment(day.date).format('D')}</span>
													<br />
													{moment(day.date).format('ddd')}
												</div>
												<div className="col-10" style={{ width: '50%' }}>
													{day.slots > 0 ? (
														<span style={{ color: 'green' }}>Spaces available</span>
													) : (
														<span style={{ color: 'red' }}>No spaces available</span>
													)}
												</div>
											</div>
										</Comp>
									</ListGroupItem>
								);
							})}
							{/* Next Month button */}
							<ListGroupItem style={{ display: 'inline-block', background: '#e8f3e8' }}>
								<div
									className="row"
									style={{
										cursor: 'pointer',
										'&:hover': {
											backgroundColor: '#f5f5f5'
										}
									}}
									onClick={() => {
										setMonthIndex(monthIndex + 1);
									}}
								>
									<div className="col-2" style={{ textAlign: 'center' }}>
										<i style={{ color: 'green' }} className="fa-solid fa-circle-arrow-right fa-xl" />
									</div>
									<div className="col-10" style={{ width: '50%' }}>
										<b style={{ color: 'green' }}>
											{/* go to november */}
											View {moment(`${year}-${month}-01`).add(1, 'month').format('MMMM')}
										</b>
									</div>
								</div>
							</ListGroupItem>
						</ListGroup>
					</ModalBody>
				) : (
					<ModalBody>
						<Spinner noTransform />
					</ModalBody>
				)}
			</Modal>
		</div>
	);
}

import React from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { useState, useEffect, useCallback } from 'react';
import { Card, Row, Col, Table, Button, Input, Label, CardHeader, Alert } from 'reactstrap';
import moment from 'moment';

import Spinner from 'src/components/Spinner';

import { AskText, AskDate, AskConfirm } from 'src/components/AskComponents';
import { useQuery, useMutation } from '@tanstack/react-query';
import graphql from 'src/utils/graphql';

import { useApp } from 'src/components/App/appContext';
import DateSelectModal from './DateSelectModal';

const query = `#graphql
query ($id: bigint!) {
  workshopBookingByPk(id: $id) {
    id
	date
	otherInfo
    equipmentMake
	equipmentModel
    equipmentSize
    equipmentSerialNumber
    equipmentDateOfManufacture
    equipmentDamage
	workshopServiceId
    equipmentType {
      id
    }
	status{
      id
      name
	  alert
	  alertColor
    }
	bookingFiles {
      file{
        id
        url
		fileName
		uploadTimestamp
      }
    }
    personId
    person {
      firstName
      lastName
      email
      mobile
    }
	workshopBookingService {
      product {
        id
        name
      }
    }
  }
  
}
`;

export default function ServiceBooking() {
	let history = useNavigate();
	const { id } = useParams();
	const { user } = useApp();
	const [dateModal, setDateModal] = useState(false);

	const { data, isLoading, error, refetch } = useQuery(
		['booking', id],
		async () => {
			const { workshopBookingByPk } = await graphql(
				{
					query,
					variables: { id }
				},
				true
			);
			return workshopBookingByPk;
		},
		{
			enabled: !!user
		}
	);

	const [person, setPerson] = useState(null);
	const [people, setPeople] = useState([]);
	const [participations, setParticipations] = useState([]);
	const [flyingSites, setFlyingSites] = useState([]);
	const [emailTemplates, setEmailTemplates] = useState([]);
	const [emailTemplate, setEmailTemplate] = useState([]);
	const [noteValue, setNoteValue] = useState('');
	const [equipmentMake, setEquipmentMake] = useState('');
	const [equipmentModel, setEquipmentModel] = useState('');
	const [redirecting, setRedirecting] = useState(false);

	useEffect(() => {
		if (!data) return;
		setPerson(data.person);
		setEquipmentMake(data.equipmentMake);
		setEquipmentModel(data.equipmentModel);
	}, [data]);

	const emailPilot = () => {
		if (emailTemplate) {
			history.push(`/admin/emailer/${emailTemplate}/${id}`);
		}
	};

	const handleEquipmentMakeChange = (value) => {
		setEquipmentMake(value);
	};

	const handleEquipmentModelChange = (value) => {
		setEquipmentModel(value);
	};

	const updateBooking = async (values) => {
		try {
			await graphql(
				{
					query: `#graphql
				mutation ($id:bigint!,$input:WorkshopBookingSetInput){
					updateWorkshopBookingByPk(pkColumns:{id:$id},_set:$input) {
						id
					}
				}
				`,
					variables: {
						id: data.id,
						input: {
							...values
						}
					}
				},
				true
			);

			await refetch();
		} catch (error) {
			alert(error.message);
		}
	};

	const redirectToStripe = async () => {
		try {
			setRedirecting(true);
			const resolvedPath = window.websiteEmbed
				? window.location.origin + window.location.pathname + '#'
				: window.location.origin;

			const { createWorkshopOrder } = await graphql({
				query: `#graphql
					mutation ($workshopBookingId: ID!, $successURL: String!, $cancelURL: String!) {
						createWorkshopOrder(workshopBookingId: $workshopBookingId, successURL: $successURL, cancelURL: $cancelURL)
					}`,
				variables: {
					workshopBookingId: id,
					successURL: `${resolvedPath}/cart/thankyou`,
					cancelURL: `${resolvedPath}/servicing/${id}`
				}
			});

			window.location = createWorkshopOrder;
		} catch (error) {
			console.error(error);
			alert(error.message);
		} finally {
			setRedirecting(false);
		}
	};

	const EditGard = ({ children }) => {
		if (data.status.id != 1) return null;
		else return <>{children}</>;
	};

	if (!user) return <h2>You need to be signed in to use this page</h2>;
	if (isLoading && !data) return <Spinner />;

	let SubmitButtonWrapper;

	if (data.status.id !== 1 || !data.date) {
		SubmitButtonWrapper = React.Fragment;
	} else if ([8, 3, 13].includes(data.workshopServiceId) && (!equipmentMake?.trim() || !equipmentModel?.trim())) {
		SubmitButtonWrapper = React.Fragment;
	} else {
		SubmitButtonWrapper = AskConfirm;
	}
	return (
		<>
			{data.status.alert && (
				<Alert color={data.status.alertColor}>
					<h4 className="alert-heading">{data.status.name}</h4>
					<p>{data.status.alert}</p>
				</Alert>
			)}
			<h2>Service Booking - #{id} </h2>
			{/* <Alert color="danger">
				Please complete all the fields below accurately, as once submitted you will not be able to edit the information.
			</Alert> */}
			{!data.date && (
				<Alert color="danger">Please book an equipment drop off date below using the date selector.</Alert>
			)}
			{[8, 3, 13].includes(data.workshopServiceId) && (
				<>
					{equipmentMake?.trim() === '' ? (
						<Alert color="danger">Please provide the equipment manufacturer.</Alert>
					) : null}
					{equipmentModel?.trim() === '' ? <Alert color="danger">Please provide the equipment model</Alert> : null}
				</>
			)}

			<Row>
				<Col md="12">
					<Card>
						<Table>
							<tbody>
								<tr>
									<th>Service Booked</th>
									<td>{data.workshopBookingService?.product?.name}</td>
								</tr>
								<tr>
									<th>Booking Status</th>
									<td>{data.status.name}</td>
								</tr>
								<tr>
									<th>Drop Off Date</th>
									<td>
										{/* Wed 5th Nov */}
										{data.date ? moment(data.date).format('ddd Do MMM') : 'N/A'}&nbsp;
										<EditGard>
											<DateSelectModal open={dateModal} setOpen={setDateModal} updateBooking={updateBooking}>
												<i className="fa fa-calendar" style={{ cursor: 'pointer' }} />
											</DateSelectModal>
										</EditGard>
									</td>
								</tr>
								<tr>
									<th>Reason for Service</th>
									<td>
										{data.otherInfo}
										<EditGard>
											<AskText
												value={data.otherInfo}
												label="Reason for Service"
												type="textarea"
												onSubmit={(e) => updateBooking({ otherInfo: e })}
											/>
										</EditGard>
									</td>
								</tr>
								<tr>
									<th>Any damage to equipment? Please describe:</th>
									<td>
										{data.equipmentDamage}
										<EditGard>
											<AskText
												value={data.equipmentDamage}
												label="Damage to Equipment?"
												type="textarea"
												onSubmit={(e) => updateBooking({ equipmentDamage: e })}
											/>
										</EditGard>
									</td>
								</tr>

								{/* HG or PG */}
								{[8, 3, 13].includes(data.workshopServiceId) && (
									<>
										<tr>
											<th>Equipment Manufacturer</th>
											<td>
												{data.equipmentMake}
												<EditGard>
													<AskText
														value={data.equipmentMake}
														label="Equipment Manufacturer"
														type="text"
														onSubmit={(v) => {
															handleEquipmentMakeChange(v);
															updateBooking({ equipmentMake: v });
														}}
													/>
												</EditGard>
											</td>
										</tr>
										<tr>
											<th>Equipment Model</th>
											<td>
												{data.equipmentModel}
												<EditGard>
													<AskText
														value={data.equipmentModel}
														label="Equipment Model"
														type="text"
														onSubmit={(e) => {
															handleEquipmentModelChange(e);
															updateBooking({ equipmentModel: e });
														}}
													/>
												</EditGard>
											</td>
										</tr>
										<tr>
											<th>Equipment Size</th>
											<td>
												{data.equipmentSize}
												<EditGard>
													<AskText
														value={data.equipmentSize}
														label="Equipment Size"
														type="text"
														onSubmit={(e) => updateBooking({ equipmentSize: e })}
													/>
												</EditGard>
											</td>
										</tr>
										<tr>
											<th>Serial Number</th>
											<td>
												{data.equipmentSerialNumber.toUpperCase()}
												<EditGard>
													<AskText
														value={data.equipmentSerialNumber.toUpperCase()}
														label="Serial Number"
														type="text"
														onSubmit={(e) => updateBooking({ equipmentSerialNumber: e })}
													/>
												</EditGard>
											</td>
										</tr>

										<tr>
											<th>Date of Manufacture</th>
											<td>
												{data.equipmentDateOfManufacture &&
													moment(data.equipmentDateOfManufacture).format('DD/MM/YYYY').toUpperCase()}
												&nbsp;
												<EditGard>
													<AskDate
														value={moment(data.equipmentDateOfManufacture).format('DD/MM/YYYY').toUpperCase()}
														label="Date of Manufacture"
														type="date"
														onSubmit={(e) => updateBooking({ equipmentDateOfManufacture: e })}
													/>
												</EditGard>
											</td>
										</tr>
									</>
								)}
							</tbody>
						</Table>
						<SubmitButtonWrapper
							title="Are you sure you want to submit this report?"
							description="Once submitted, you will not be able to edit the information."
							onSubmit={async (e) => {
								await updateBooking({ statusId: 2 });
								await redirectToStripe();
							}}
						>
							{data.status.id === 2 ? (
								<Button disabled={redirecting} onClick={() => redirectToStripe()} color="warning" block>
									<i className="fa-brands fa-cc-stripe fa-xl" />
									&nbsp; Pay For Service&nbsp;
									{redirecting && <i className="fa fa-spinner fa-spin ml-2" />}
								</Button>
							) : (
								<Button disabled={SubmitButtonWrapper === React.Fragment} color="primary" block>
									<i className="fa fa-paper-plane" />
									&nbsp; Submit Booking
								</Button>
							)}
						</SubmitButtonWrapper>
					</Card>

					{data.status.id !== 1 && (
						<Card className="mt-3">
							<CardHeader>
								<Label for="exampleSelect">Uploaded Service Reports</Label>
							</CardHeader>
							<Table>
								<tbody>
									{data.bookingFiles.length > 0 ? (
										data.bookingFiles.map((file) => (
											<tr key={file.file.id}>
												<td>
													<a href={file.file.url} target="_blank" rel="noopener noreferrer">
														{file.file.fileName} -{moment(file.file.uploadTimestamp).format('DD/MM/YYYY HH:mm')}
													</a>
												</td>
											</tr>
										))
									) : (
										<tr>
											<td>No files uploaded</td>
										</tr>
									)}
								</tbody>
							</Table>
						</Card>
					)}
				</Col>
			</Row>
		</>
	);
}
